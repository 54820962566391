import React from "react";
import Im from "immutable";

import { ErrorBoundary } from "traec-react/errors";
import ReportRowErrorBoundary from "./error";
import { BreadCrumb } from "AppSrc/project/utils";
import ReportCategoryItem from "./reportCategoryItem";
import { rpName } from "storybook-dashboard/dashboard/project/reportStatus";
import { getCommitStatus } from "AppSrc/project/commits/row";
import { dateToText } from "AppSrc/project/report/utils";
import Octicon from "react-octicon";
import { Tooltip } from "react-tippy";

import { exportToCSV } from "../metrics";

import { isRequiredOnFreq, NotRequiredMsg, ReportPeriodText } from "./reportMetricRow";
import { useModal } from "storybook-dashboard/components/modal";
import { pathHasInputs } from "./utils";

import CommentThread from "AppSrc/project/comments";

import ReportContextWrapper, { useReportContext } from "./context";
import ProjectContextWrapper from "../context";
import { useFullIds } from "../utils/hooks";

import { Submit } from "AppSrc/project/report/submit";
import { ValidateAndSubmitModal } from "./validate";

const showApprovals = (commit) => {
  return Im.Set(["Pending Approval", "OK for Submission"]).has(commit?.getIn(["status", "name"]));
};

/* ###### COMPONENTS ###### */

const REPORT_STATUS_PROPS = {
  notstarted: { name: "alert", className: "text-danger" },
  incomplete: { name: "alert", className: "text-warning" },
  complete: { name: "check", className: "text-success" },
};

const REPORT_TOOLTIPS = {
  notstarted: "You have not started completing this section",
  incomplete: "You have started entering data but are yet to mark the section as complete",
  complete: "You have marked this section as complete",
};

const APPROVAL_STATUS_PROPS = {
  rejected: { name: "stop", className: "text-danger" },
  unreviewed: { name: "clock", className: "text-secondary" },
  approved: { name: "thumbsup", className: "text-success" },
};

const APPROVAL_TOOLTIPS = {
  rejected: "This section was rejected",
  unreviewed: "This section is pending approval or rejection",
  approved: "This section was approved",
};

function ReportDueDateString({ reportDueDate }) {
  console.log("report due date >>", reportDueDate);
  return reportDueDate ? dateToText(reportDueDate, 0) : "";
}

function CategoryTabLabel(props) {
  let { commit } = useReportContext();
  let { name, _id, show, isSectionComplete, hasInputs, categoryPath } = props;

  // Render approval tabs if this report is for approval
  let for_approval = showApprovals(commit);
  let sectionsApproved = commit.getIn(["meta_json", "_sectionsApproved"]) || Im.Map();
  let isSectionApproved = sectionsApproved.get(categoryPath);

  let completeState = isSectionComplete ? "complete" : hasInputs ? "incomplete" : "notstarted";
  let approvedState = isSectionApproved === true ? "approved" : isSectionApproved === false ? "rejected" : "unreviewed";

  // Get some parameters depending on the type of report we are approving
  let STATUS_PROPS = for_approval ? APPROVAL_STATUS_PROPS : REPORT_STATUS_PROPS;
  let TOOLTIPS = for_approval ? APPROVAL_TOOLTIPS : REPORT_TOOLTIPS;

  // Get the properties of the icon that we will use
  let section_status = for_approval ? approvedState : completeState;
  let tooltipText = TOOLTIPS[section_status];
  let status = STATUS_PROPS[section_status] || {};

  let iconName = status.name || "alert";
  let iconClass = status.className || "text-warning";

  return (
    <a className={`nav-item nav-link ${show ? "active" : ""}`} data-toggle="tab" href={`#${_id}`}>
      <Tooltip
        animateFill={false}
        html={
          <div className="text-left">
            <p>{tooltipText}</p>
          </div>
        }
      >
        {name} <Octicon name={iconName} className={iconClass} />
      </Tooltip>
    </a>
  );
}

function NavButton({ tabDetails, index, step, label }) {
  let toDetails = index + step >= 0 ? tabDetails.get(index + step) : null;
  console.log("NavButton", index, label, step, toDetails, tabDetails);
  if (!toDetails) {
    return null;
  }
  return (
    <button
      className="btn btn-sm btn-secondary mr-2 mt-0 mb-0 pt-0 pb-0"
      onClick={(e) => {
        // Use jQuery to emulate pressing next link in the tab
        let current = jQuery(".nav-tabs > .active");
        let next = step > 0 ? current.next("a") : current.prev("a");
        next.trigger("click");
      }}
    >
      {label}
    </button>
  );
}

function NavButtons(props) {
  return (
    <ErrorBoundary>
      <div className="float-right m-0">
        <NavButton {...props} step={-1} label="<< Previous section" />
        <NavButton {...props} step={1} label="Next section >>" />
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function ApproveButton({ id, labelText, checkedState, state, setState, checkedClass }) {
  let checked = state === checkedState;
  // onClick={e => setState(checkedState)}
  let _class = checked ? checkedClass || "btn-primary" : "btn-secondary-outline";
  return (
    <React.Fragment>
      <button className={`btn btn-sm mt-0 pt-0 mb-0 pb-0 ml-2 ${_class}`} onClick={() => setState(checkedState)}>
        {labelText}
      </button>
    </React.Fragment>
  );
}

function ApproveButtonSet({ idKey, state, setState }) {
  let _props = { state, setState };
  return (
    <ErrorBoundary>
      <ApproveButton
        id={`${idKey}-on`}
        labelText="Accepted"
        checkedState={true}
        checkedClass={"btn-success"}
        {..._props}
      />
      <ApproveButton
        id={`${idKey}-na`}
        labelText="Unreviewed"
        checkedState={null}
        checkedClass={"btn-secondary"}
        {..._props}
      />
      <ApproveButton
        id={`${idKey}-off`}
        labelText="Requires Revision"
        checkedState={false}
        checkedClass={"btn-danger"}
        {..._props}
      />
    </ErrorBoundary>
  );
}

function CheckCategoryApproved(props) {
  let { categoryPath, commit, setSectionApproved } = props;

  let sectionsApproved = commit.getIn(["meta_json", "_sectionsApproved"]) || Im.Map();
  let state = sectionsApproved.get(categoryPath, null);
  //console.log("Rendering CheckCategoryApproval", state);

  const setState = (value) => {
    console.log(`Setting section ${categoryPath} to ${value}`);
    setSectionApproved(categoryPath, value);
  };

  return (
    <ErrorBoundary>
      <div className="form-check float-right mb-2 mr-2">
        <label className="form-check-label" htmlFor="categoryComplete">
          <b>Mark section as:</b> <ApproveButtonSet idKey={categoryPath} state={state} setState={setState} />
        </label>
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

function CheckCategoryComplete(props) {
  let { categoryPath, disableInputs, freqDetails } = props;
  const { setSectionApproved, setSectionComplete, commit } = useReportContext();
  const sectionsComplete = commit.getIn(["meta_json", "_sectionsComplete"]) || Im.Map();
  const isSectionComplete = sectionsComplete.get(categoryPath, freqDetails.dueThisReport === false);
  // If the report is pending approval then set the section to approved/rejected
  if (showApprovals(commit)) {
    return <CheckCategoryApproved {...props} commit={commit} setSectionApproved={setSectionApproved} />;
  }

  return (
    <ErrorBoundary>
      <div className="form-check float-right mb-2 mr-2">
        <input
          type="checkbox"
          className="form-check-input"
          id="categoryComplete"
          disabled={disableInputs}
          checked={isSectionComplete}
          onChange={(e) => setSectionComplete(categoryPath, !isSectionComplete)}
        />
        <label className="form-check-label" htmlFor="categoryComplete">
          <b>Mark section as complete</b>
        </label>
      </div>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

export function FrequencyAlert(props) {
  let { dueThisReport, from_date, dueDate } = props;
  if (!dueThisReport || !from_date || !dueDate) {
    return null;
  }
  return (
    <div className="alert alert-warning text-center" role="alert">
      Data reported for this section is for the period <br />
      <ReportPeriodText isRequiredPeriodically={props} preamble={" "} />
    </div>
  );
}

export function NotRequiredAlert(props) {
  return (
    <div className="alert alert-warning text-center" role="alert">
      This section is not due in this reporting period <br />
      <NotRequiredMsg {...props} />
    </div>
  );
}

function CategoryTabContent(props) {
  let { _id, categoryPath, index, tabDetails, show, freqDetails } = props;

  // If this tab has a reporting frequency then
  let notRequiredThisPeriod = (freqDetails || {}).dueThisReport === false;
  if (notRequiredThisPeriod) {
    return (
      <div id={_id} className={`tab-pane fade ${show ? "show active" : ""}`}>
        <NotRequiredAlert {...freqDetails} />
        <NavButtons tabDetails={tabDetails} index={index} />
      </div>
    );
  }

  return (
    <div id={_id} className={`tab-pane fade ${show ? "show active" : ""}`}>
      <FrequencyAlert {...freqDetails} />
      <CategoryTable {...props} asFullTable={true} hideTitleRow={true} />
      <hr />
      <CheckCategoryComplete {...props} />
      <hr />
      <CommentThread path={categoryPath} />
      <hr />
      <NavButtons tabDetails={tabDetails} index={index} />
    </div>
  );
}

function TabbedReport(props) {
  let { commit, nodesByPath, rootNode, getChildren, inputValueMap } = useReportContext();
  const { currentReportingPeriod } = props;
  const sortKey = commit.getInPath("meta_json.sortKey");
  const categories = getChildren(rootNode)
    .toList()
    .filter((i) => i.get("_type") === "tree");

  if (!categories?.size) {
    console.log("TabbedReport No categories");
    return null;
  }

  const categoryPathMap = categories.reduce((acc, cur) => acc.set(cur.get("_path"), cur), Im.Map());

  const inputsByCategoryPath = categoryPathMap.map((tree, categoryPath) => {
    return nodesByPath
      .toList()
      .filter((value, key) => value.get("_type") == "metricscore" && value.get("_path").startsWith(categoryPath))
      .map((ms) => inputValueMap?.get(ms?.getIn(["metric", "uid"])));
  });

  const tabDetails = categories
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => {
      const name = tree.get("name");
      const categoryPath = tree.get("_path");
      const _id = `path_${categoryPath}`;
      const sectionsComplete = commit.getIn(["meta_json", "_sectionsComplete"]) || Im.Map();
      const freqDetails = isRequiredOnFreq(tree.get("meta_json"), currentReportingPeriod) || {};
      const isSectionComplete = sectionsComplete.get(categoryPath, freqDetails.dueThisReport === false);
      const hasInputs = pathHasInputs(categoryPath, inputsByCategoryPath);
      return { name, _id, tree, categoryPath, isSectionComplete, hasInputs, freqDetails };
    });

  const tabLabels = tabDetails.map((details, i) => (
    <CategoryTabLabel key={i} {...details} commit={commit} show={i == 0} tabDetails={tabDetails} />
  ));

  let tabContents = tabDetails.map((details, i) => (
    <CategoryTabContent
      key={i}
      index={i}
      {...props}
      {...details}
      tabDetails={tabDetails}
      show={i == 0}
      sortKey={sortKey}
    />
  ));

  return (
    <ErrorBoundary>
      <div className="nav nav-tabs" role="tablist">
        {tabLabels}
      </div>

      <ErrorBoundary>
        <div className="tab-content">{tabContents}</div>
      </ErrorBoundary>
    </ErrorBoundary>
  );
}

function CategoryTableWrapper(props) {
  let { asFullTable, tree } = props;

  let { commit, nodesByPath, rootNode, getChildren, inputValueMap } = useReportContext();

  const categories = getChildren(rootNode)
    .toList()
    .filter((i) => i.get("_type") === "tree");

  const categoryPathMap = categories.reduce((acc, cur) => acc.set(cur.get("_path"), cur), Im.Map());

  const inputsByCategoryPath = categoryPathMap.map((tree, categoryPath) => {
    return nodesByPath
      .toList()
      .filter((value, key) => value.get("_type") == "metricscore" && value.get("_path").startsWith(categoryPath))
      .map((ms) => inputValueMap?.get(ms?.getIn(["metric", "uid"])));
  });

  let name = tree.get("name");
  let categoryPath = tree.get("_path");
  let _id = `path_${categoryPath}`;
  let sectionsComplete = commit.getIn(["meta_json", "_sectionsComplete"]) || Im.Map();
  let isSectionComplete = sectionsComplete.get(categoryPath, false);
  let hasInputs = pathHasInputs(categoryPath, inputsByCategoryPath);

  const tabDetails = {
    name,
    _id,
    tree,
    categoryPath,
    isSectionComplete,
    hasInputs,
  };

  // If not rendering a full table then just render children directly
  if (!asFullTable) {
    return <ReportCategoryItem {...props} tabDetails={tabDetails} />;
  }
  // Wrap the children (<tr> elements) in a table
  return (
    <ErrorBoundary>
      <CategoryHelpText tree={tree} />
      <table className="table table-sm table-hover">
        <ReportTableHeader />
        <tbody>
          <ReportCategoryItem {...props} tabDetails={tabDetails} />
        </tbody>
      </table>
    </ErrorBoundary>
  );
}

function CategoryHelpText({ tree }) {
  let helpText = tree.getIn(["meta_json", "helpText"]);
  if (!helpText) {
    return null;
  }
  return (
    <div className="alert alert-primary mt-2 mb-2" role="alert">
      {helpText}
    </div>
  );
}

function CategoryTable(props) {
  let { tree, inputErrors, disableInputs, updateError, asFullTable, hideTitleRow, tabDetails } = props;

  // Pass through only validation errors for this issue/category
  let path = tree.get("_path");
  let _inputErrors = (inputErrors || Im.Map()).get(path);

  // Clean up the props to avoid passing unneccessary stuff that will cause a category to update (for performance)
  let _props = { ...props };
  let keysToRemove = [
    "tabDetails",
    "inputErrors",
    "reportScores",
    "scoreValues",
    "inputsByCategoryPath",
    "metricScores",
  ];
  for (let key of keysToRemove) {
    delete _props[key];
  }

  return (
    <CategoryTableWrapper
      {..._props}
      asFullTable={asFullTable}
      tree={tree}
      path={path}
      categoryPath={path} // Keep a record of the category/issue path (for section complete and validation)
      inputErrors={_inputErrors}
      hideTitleRow={hideTitleRow}
      disableInputs={disableInputs}
      updateInputErrors={updateError}
      tabDetails={tabDetails}
    />
  );
}

function CategoryTableRows(props) {
  let { commit, rootNode, getChildren } = useReportContext();
  const categoryTrees = getChildren(rootNode)
    .toList()
    .filter((i) => i.get("_type") === "tree");
  const sortKey = commit.getInPath("meta_json.sortKey");

  return categoryTrees
    .sortBy((i) => i.get(sortKey || "name"))
    .map((tree, i) => (
      <ReportRowErrorBoundary key={i} msg={<td colSpan="100%">Error loading issue section: {tree.get("name")}</td>}>
        <CategoryTable {...props} tree={tree} hideTitleRow={false} asFullTable={false} />
      </ReportRowErrorBoundary>
    ));
}

function ReportTableHeader(props) {
  const titles = [
    { name: "Metric", width: "50%", className: "text-left" },
    { name: "Units", width: "5%", className: "text-left" },
    { name: "Value", width: "10%", className: "text-left" },
    { name: "Comments", width: "25%", className: "text-left" },
    { name: "N/A", width: "5%" },
    { name: "Past data", width: "5%", className: "text-nowrap" },
  ];
  const headCols = titles.map((title, i) => (
    <th
      key={i}
      width={title.width}
      className={`${title.className} border-top-0` || "text-center border-top-0"}
      scope="col"
    >
      {title.name}
    </th>
  ));
  return (
    <thead>
      <tr>{headCols}</tr>
    </thead>
  );
}

function TableReport(props) {
  let { commit } = useReportContext();
  return (
    <table className="table table-sm table-hover">
      <ReportTableHeader />
      <tbody>
        <CategoryTableRows {...props} commit={commit} />
      </tbody>
    </table>
  );
}

function ReportHeadline({ commit, disableInputs }) {
  let headline = null;
  if (!disableInputs) {
    headline = null; // "Report Due"
  } else if (disableInputs && !getCommitStatus(commit)) {
    headline = null; // "Report Due"
  } else {
    headline = "Report Submitted";
  }
  return headline ? <h3>{headline}</h3> : null;
}

function ReportCommitForm(props) {
  let { commitId } = useFullIds();
  let { isLoading, inputErrors, needsRevision } = props;

  let { setModal } = useModal();

  if (isLoading) {
    return null;
  }

  return (
    <ErrorBoundary title="Error loading submission button">
      <div className="mt-2" style={{ clear: "both" }} />
      <Submit
        {...props}
        postCommit={(e) => setModal(<ValidateAndSubmitModal reportId={commitId} method="POST" />)}
        patchCommit={(e) => setModal(<ValidateAndSubmitModal reportId={commitId} method="PATCH" />)}
        needsRevision={needsRevision}
        inputErrors={inputErrors}
      />
      <div style={{ clear: "both" }} className="mb-2" />

      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

const REPORT_TYPES = {
  classic: TableReport,
  tabbed: TabbedReport,
};

function ReportBody(props) {
  let { commit } = useReportContext();

  // Get the format of the report to render (tabbed or classic table)
  let report_layout = commit.getIn(["meta_json", "report_layout"]) || "classic";
  let ReportComponent = REPORT_TYPES[report_layout] || TableReport;

  return (
    <ErrorBoundary title="Error loading report">
      {/* Render the table for all inputs */}
      <ReportComponent {...props} />
      <ReportCommitForm {...props} commit={commit} />
    </ErrorBoundary>
  );
}

export const isMetaComplete = (meta) => {
  if (!meta) {
    return false;
  }
  let input_details = meta?.get("input_details") || Im.Map();
  let fields = input_details.get("fields") || Im.List();
  console.log("Checking if report meta complete", fields?.toJS(), meta?.toJS());
  return fields.every((i) => meta?.get(i?.get("header")));
};

function ExportMetrics(props) {
  return (
    <ErrorBoundary>
      <p className="float-right" style={{ cursor: "pointer" }} onClick={() => exportToCSV(props)}>
        <Octicon name="desktop-download" /> Export report metrics
      </p>
      <div style={{ clear: "both" }} />
    </ErrorBoundary>
  );
}

const needsRevision = ({ isResponsible, commit }) => {
  return isResponsible && commit.getIn(["status", "name"]) === "Requires Revision";
};

/**********************
    MENU METHODS
  **********************/

const getReportLayout = () => {
  let { commit } = props;
  return (commit ? commit.getIn(["meta_json", "report_layout"]) : "classic") || "classic";
};

const updateError = (metricName, value, isRequiredInPeriod, categoryPath) => {
  let { inputErrors } = state;
  let _categoryErrors = Im.Map();

  categoryPath = categoryPath || "";
  let categoryErrors = inputErrors.get(categoryPath) || Im.Map();

  if (typeof value === "number" || value == null) {
    _categoryErrors = categoryErrors.delete(metricName);
  } else {
    _categoryErrors = categoryErrors.set(metricName, Im.Map({ name: metricName, value: value }));
  }

  let _inputErrors = inputErrors.set(categoryPath, _categoryErrors);

  console.log("Updating input errors for category", categoryPath);
  if (getReportLayout() == "classic") {
    setState({
      inputErrors: _inputErrors,
      sectionsComplete: updateSectionComplete(categoryPath),
    });
  } else {
    setState({
      inputErrors: _inputErrors,
    });
  }
};

export default function Report(props) {
  return (
    <ProjectContextWrapper>
      <ReportContextWrapper>
        <ReportWithCommitNodes {...props} />
      </ReportContextWrapper>
    </ProjectContextWrapper>
  );
}

const isOnHold = ({ commit }) => {
  let commitStatus = getCommitStatus(commit);
  return commitStatus && commitStatus.startsWith("Not for");
};

function ReportWithCommitNodes(props) {
  let { trackerId, commitId } = useFullIds();
  let { commit, reportingPeriods, isResponsible, getChildren, rootNode } = useReportContext();
  let status = getCommitStatus(commit);
  status = status ? ` | ${status}` : null;

  const currentReportingPeriodId = commit?.get("reporting_period");
  const currentReportingPeriod = reportingPeriods
    ?.reduce((a, c) => a.set(c.get("uid"), c), Im.Map())
    ?.get(currentReportingPeriodId);

  const categoryTrees = getChildren(rootNode)
    .toList()
    .filter((i) => i.get("_type") === "tree");

  let shouldHide =
    !isResponsible || (isOnHold({ commit }) && !needsRevision({ isResponsible, commit })) || showApprovals(commit);

  return (
    <ErrorBoundary>
      <ReportHeadline commit={commit} />
      <h5>
        <BreadCrumb />
      </h5>
      <p>
        Reporting period:
        <b>
          {rpName(currentReportingPeriod)}
          {status}
        </b>
      </p>
      <p>
        Report due on:
        <b>
          <ReportDueDateString reportDueDate={commit?.get("due_date")} />
        </b>
      </p>

      <ExportMetrics commitId={commitId} trackerId={trackerId} />

      <ReportBody
        disableInputs={shouldHide}
        needsRevision={needsRevision}
        updateError={updateError}
        currentReportingPeriod={currentReportingPeriod}
        categoryTrees={categoryTrees}
      />
    </ErrorBoundary>
  );
}
