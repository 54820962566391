import React from "react";
import { rpName } from "storybook-dashboard/dashboard/project/reportStatus";
import Im from "traec/immutable";
import { BSBtnDropdown } from "traec-react/utils/bootstrap";

export const ReportingPeriodsDropdown = (props) => {
  let links = Im.List();

  try {
    links = props.reportingPeriods
      .toList()
      .sortBy((reportingPeriod) => reportingPeriod.get("startDate"))
      .map((reportingPeriod) => {
        return {
          name: rpName(reportingPeriod),
          onClick: (e) => props.updateReportingPeriodId(e, reportingPeriod.get("uid")),
        };
      });
  } catch (e) {}
  let formattedReportingPeriod = rpName(props.selectedReportingPeriod);

  return (
    <div className="row mb-2 pb-2">
      <div className="col-12">
        <BSBtnDropdown header={`Reporting Period: ${formattedReportingPeriod}`} links={links.toJS()} />
      </div>
    </div>
  );
};
