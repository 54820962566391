import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import Toggle from "react-toggle";

import Im from "immutable";
import Moment from "moment";

import useApi, { fetcher } from "storybook-dashboard/utils/fetching";
import { rpName } from "storybook-dashboard/dashboard/project/reportStatus";

import { Status } from "AppSrc/user/projectItem/reportingPackageStatusItem";
import { listToMap } from "AppSrc/project/metrics/node";

const getStatusName = (commit) => {
  let status = commit.status?.name || "Nothing Received";

  if (status == "Nothing Received") {
    if (commit.due_date && new Date(commit.due_date) < new Date()) {
      status = "Overdue";
    }
  }

  return status;
};

const Notifications = () => {
  const [data, setData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [requiresAction, setRequiresAction] = useState(true);
  const [filterText, setFilterText] = useState("");

  const columns = [
    {
      name: "Company",
      format: (r) => {
        return (
          <Link className={`font-weight-bold`} to={`/company/${r.project.company.uid}`}>
            <u>{r.project?.company?.name}</u>
          </Link>
        );
      },
      selector: (r) => {
        return r.project?.company?.name;
      },
      sortable: true,
    },
    {
      name: "Project",
      format: (r) => {
        return (
          <Link className={`font-weight-bold`} to={`/project/${r.project.uid}`}>
            <u>{r.project?.name}</u>
          </Link>
        );
      },
      selector: (r) => {
        return r.project?.name;
      },
      sortable: true,
    },
    {
      name: "Reporting Package",
      format: (r) => {
        return (
          <Link className={`font-weight-bold`} to={`/project/${r.project.uid}/wpack/${r.commit.ref}/evals`}>
            <u>{r.ref?.name}</u>
          </Link>
        );
      },
      selector: (r) => {
        return r.ref?.name;
      },
      sortable: true,
    },
    {
      name: "Reporting Period",
      format: (r) => rpName(Im.fromJS(r.commit.reporting_period_data)),
      sortable: true,
      selector: (r) => {
        return r.commit.reporting_period_data.startDate;
      },
    },
    {
      name: "Status",
      format: (r) => (
        <Link to={`/project/${r.project.uid}/wpack/${r.commit.ref}/report/${r.commit.uid}`}>
          <Status status={getStatusName(r.commit)} />
        </Link>
      ),
      sortable: true,
      selector: (r) => getStatusName(r.commit),
    },
    {
      format: (r) => `${Moment(r.commit.meta_json.lastUpdateOn).format("Do MMM YY")}`,
      name: "Last Updated",
      sortable: true,
      selector: (r) => {
        return r.commit.meta_json.lastUpdateOn;
      },
    },
  ];

  let { data: projects } = useApi(`/api/project/`);
  let { data: refs } = useApi(`/api/tracker/ref/`);
  let { data: commits, mutate: mutateCommits } = useApi(`/api/tracker/commit/?requiresAction=${requiresAction}`);

  useEffect(() => {
    if (projects && commits && refs) {
      console.log("Notifications useEffect", projects?.toJS(), commits?.toJS());
      let projectsById = projects.reduce((a, p) => a.set(p.get("uid"), p), Im.Map());
      let refsById = refs.reduce((a, p) => a.set(p.get("uid"), p), Im.Map());
      const rows = commits
        .map((commit) => {
          let project = projectsById.get(commit.get("project"));
          let ref = refsById.get(commit.get("ref"));
          return {
            project,
            commit,
            ref,
          };
        })
        .filter((r) => {
          if (!r.ref || r.ref?.get("name") === "master") return false;
          if (r.commit.status?.name?.startsWith("Not for")) return false;
          if (!filterText) return true;
          return (
            r.project.get("name").toLowerCase().includes(filterText.toLowerCase()) ||
            r.project.getIn(["company", "name"]).toLowerCase().includes(filterText.toLowerCase()) ||
            r.ref.get("name").toLowerCase().includes(filterText.toLowerCase())
          );
        });
      setData(rows.toJS());
    }
  }, [projects, commits, refs, filterText]);

  const pendingApprovalSelected = (r) => r.filter((r) => r.commit.status.name === "Pending Approval").length > 0;

  const requiresRevisionSelected = (r) => r.filter((r) => r.commit.status.name === "Requires Revision").length > 0;

  const canApproveOrReject = (r) => pendingApprovalSelected(r) && !requiresRevisionSelected(r);

  const mergeCommits = ({ existing, updated }) => {
    let commitsById = listToMap(existing, "uid");
    let newCommitsById = listToMap(Im.fromJS(updated), "uid");
    return commitsById.merge(newCommitsById).toList();
  };

  const markSelected = async ({ e, status }) => {
    e.preventDefault();
    const payloads = [];
    for (const row of selectedRows) {
      const { tracker, ref, uid } = row.commit;
      const url = `/api/tracker/${tracker}/ref/${ref}/commit/${uid}/`;
      const data = { status, comment: "Bulk Edit" };
      const { payload } = await fetcher(url, "PATCH", data).catch((err) => alert(err));
      payloads.push(payload.toJS());
    }
    mutateCommits(mergeCommits({ existing: commits, updated: payloads }), undefined, false);
  };

  return (
    <div>
      <label>
        <Toggle defaultChecked={!requiresAction} onChange={() => setRequiresAction(!requiresAction)} />
        <span>Include approved reports</span>
      </label>
      <div className="d-flex justify-content-between px-2 my-3">
        <div className="d-inline-flex align-items-center">
          <p className="text-muted text-nowrap m-0 pr-1">Quick Filter:</p>
          <input
            className="form-control"
            type="text"
            placeholder="Filter..."
            onChange={(e) => setFilterText(e.target.value)}
          />
        </div>
      </div>
      <form>
        <DataTable
          columns={columns}
          data={data}
          dense={true}
          highlightOnHover={true}
          onSelectedRowsChange={({ selectedRows }) => setSelectedRows(selectedRows)}
          pagination
          selectableRows
        />
        <div className="text-right">
          <button
            type="submit"
            className="btn btn-sm btn-primary"
            onClick={(e) => markSelected({ e, status: "APPROVE" })}
            disabled={!canApproveOrReject(selectedRows)}
          >
            Approve
          </button>
          <button
            type="submit"
            className="btn btn-sm btn-primary ml-1"
            onClick={(e) => markSelected({ e, status: "REJECT" })}
            disabled={!canApproveOrReject(selectedRows)}
          >
            Reject
          </button>
          <button
            type="Submit"
            className="btn btn-sm btn-primary ml-1"
            onClick={(e) => {
              e.preventDefault();
              const ids = selectedRows.map((r) => r.commit.uid);
              const url = `/metrics?reports=${ids}`;
              window.location = url;
            }}
            disabled={selectedRows.length < 1}
          >
            View combined summary of selected reports
          </button>
        </div>
      </form>
    </div>
  );
};

export default Notifications;
