import React from "react";
import { rpName } from "storybook-dashboard/dashboard/project/reportStatus";
import { BSCard } from "traec-react/utils/bootstrap";
import Im from "traec/immutable";

export const SubmittedReports = (props) => {
  let { reportingPeriods } = props;
  return (
    <BSCard
      title="Submitted Reports"
      body={
        <table className="table-responsive table-bordered table-sm">
          <tbody>
            <ReportRows reportingPeriods={reportingPeriods} />
          </tbody>
        </table>
      }
    />
  );
};

const ReportRows = (props) => {
  if (!props.reportingPeriods) {
    return (
      <tr>
        <td colSpan={0}>
          <div>No Reports Submitted</div>
        </td>
      </tr>
    );
  }
  let rows = props.reportingPeriods
    .toList()
    .filter((period) => {
      if (period.getInPath("meta_json.apportionment.status") === "submitted") {
        return period;
      }
    })
    .sortBy((period) => period.get("startDate"));

  let projects = getProjects(rows);
  rows = rows.map((period, i) => <ReportRow key={i} reportingPeriod={period} projects={projects} />);
  if (!rows || !rows.size) {
    return <div>No Reports Submitted</div>;
  } else {
    rows = rows.insert(0, <Header key={-1} projects={projects} />);
    return rows;
  }
};
const ReportRow = (props) => {
  return (
    <tr>
      <td>{rpName(props.reportingPeriod)}</td>
      <ClientProjects reportingPeriod={props.reportingPeriod} projects={props.projects} />
    </tr>
  );
};

const ClientProjects = (props) => {
  let apportionments = props.reportingPeriod.getIn(["meta_json", "apportionment"]);
  let projects = Im.Map();
  props.projects.map((project, i) => {
    projects = projects.set(project, null);
    apportionments.delete("status").map((apportionment) => {
      if (submissionName(apportionment) === project) {
        projects = projects.set(project, apportionment.get("value"));
      }
    });
  });
  projects = projects.toList().map((project, i) => <td key={i}>{project ? `${project * 100} %` : "Not Submitted"}</td>);
  return projects;
};

const Header = (props) => {
  let projects = props.projects.toList().map((project, i) => {
    return <th key={i}>{project}</th>;
  });

  return (
    <React.Fragment>
      <tr>
        <th>Reporting Periods</th>
        {projects}
      </tr>
    </React.Fragment>
  );
};

const submissionName = (meta_submission) => {
  return `${meta_submission.get("companyName")} - ${meta_submission.getInPath("project.name")}`;
};

export const getProjects = (rows) => {
  let projects = Im.Set();
  rows.map((period) =>
    period
      .getInPath("meta_json.apportionment")
      .delete("status")
      .map((submission_meta) => (projects = projects.add(submissionName(submission_meta)))),
  );

  return projects;
};
