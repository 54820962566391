import React from "react";
import chroma from "chroma-js";
import { ReportingPackageStatusItem } from "AppSrc/user/projectItem/reportingPackageStatusItem";
import { rpName } from "storybook-dashboard/dashboard/project/reportStatus";

export const RelatedCommits = ({ project, refs, depth }) => {
  let commits = project.get("related_commits");
  if (!commits) {
    return null;
  }

  let projectId = project.get("uid");

  return commits.map((commit, i) => {
    if (commit.get("is_staging")) {
      return null;
    }
    let commitId = commit.get("uid");
    let refId = commit.get("ref");
    if (!refId) {
      return null;
    }
    let refName = getRefName({
      refs: refs,
      refId,
      trackerId: commit.get("tracker"),
    });

    // Get the string of this reporting period
    let dates = rpName(commit.get("reportin_period_data"));

    // Get the status
    let status = commit.getInPath("meta_json.status");
    switch (status) {
      case "pending_approval":
        status = "Pending Approval";
        break;
      case "pending_revision":
        status = "Requires Revision";
        break;
      case "approved":
        status = "Approved";
        break;
      default:
        break;
    }

    let color = chroma("#ffff99").brighten(1).hex();

    return (
      <ReportingPackageStatusItem
        index={i}
        key={i}
        projectId={projectId}
        refId={refId}
        refName={refName}
        commitId={commitId}
        status={status}
        color={color}
        dates={dates}
        depth={depth}
      />
    );
  });
};

export default RelatedCommits;

const getRefName = (props) => {
  // This is not required - if we didn't get a Ref from the tracker_refs endpoint
  // then it shouldn't be fetched anyway
  /*useEffect(() => {
    Traec.fetchRequiredFor({ props, requiredFetches: [
      new Traec.Fetch("tracker_ref", "read"),
    ]})
  });*/

  let { refs, refId, trackerId } = props;
  let name = refs ? refs.getIn([refId, "name"]) : null;
  if (name) {
    return name;
  } else {
    return "Reporting Package";
  }
};
